<template>
	<div class="">
		<div
			class="full-height flex-column overflow-y-auto"
		>
			<div
				class="justify-space-between bg-white pb-30"
			>
				<div class="flex-1  pa-10 mr-10">
					<h6 class="under-line-identify">계정정보</h6>
					<table class="mt-10 table th-left td-left">
						<col width="120px">
						<col width="auto">
						<col width="120px">
						<col width="auto">
						<tbody>
						<tr>
							<th>영업단 구분 <span class="color-red">*</span></th>
							<td
								v-if="codes.A001"
							>
								<template
									v-for="(code) in codes.A001.items"
								>
									<button
										v-if="item.agency_type == code.total_code"
										:key="code.total_code"

										class="pa-5-10"
										:class="item.agency_type == code.total_code ? 'bg-green' : 'bg-default'"

									>{{ code.code_name }}</button>
								</template>
							</td>
							<th>소속 영업점 <v-icon v-if="is_search_upper" class="color-green" @click="onAgencyType">mdi mdi-card-search</v-icon></th>
							<td>
								{{ text_upper }}
							</td>
						</tr>
						<tr>
							<th>아이디</th>
							<td>{{ item.agency_id }}</td>
							<th>비밀번호</th>
							<td class="text-right">
								<button
									class="bg-identify pa-5-10"
									@click="isPassword"
								>비밀번호 초기화</button>
							</td>
						</tr>
						<tr>
							<th>이름 <span class="color-red">*</span></th>
							<td>
								<input
									type="text"
									v-model="item.account_name"
									maxlength="20"
									class="input-box full-width"
									placeholder="이름을 입력하세요"
								/>
							</td>
							<th>휴대폰 번호</th>
							<td>
								<input
									type="number"
									v-model="item.account_phone_number"
									:ruels="[rules.max(item, 'account_phone_number', 12)]"
									class="input-box full-width"
									placeholder="휴대폰번호를 입력하세요"
								/>
							</td>
						</tr>
						<tr>
							<th>가입일</th>
							<td class="position-relative">
								<input
									v-model="item.join_date"
									class="input-box full-width"
									placeholder="가입일을 선택하세요"
									readonly
									@click="is_data_pick = !is_data_pick"
								/>
								<v-date-picker
									v-if="is_data_pick"
									v-model="item.join_date"
									no-title
									scrollable
									class="position-absolute box"
									style="top: 45px; left: -5px"
									@change="is_data_pick = false"
								></v-date-picker>
							</td>
							<th>계정 상태</th>
							<td class="">
								<button
									v-for="(code, index) in codes.S003.items"
									:key="code.total_code + '_' + index"

									class="pa-5-10 mr-1"
									:class="item.account_status == code.code_value ? 'bg-' + code.code_color: 'bg-default'"
									@click="item.account_status = code.code_value "
								>{{ code.code_name }}</button>
							</td>
						</tr>
						</tbody>
					</table>

					<div class="mt-10">
						<h6>공급가 마진</h6>
						<table
							class="table"
						>
							<thead>
							<tr>
								<th>PG 명</th>
								<th>PG 원가</th>
								<th>마진율</th>
								<th>공급가</th>
								<th>적용여부</th>
							</tr>
							</thead>
							<tbody>
							<tr>
								<td colspan="5" class="text-left bg-base">일반 결제</td>
							</tr>
							<tr
								v-for="(pg, index) in pg_sales_list"
								:key="'pg_' + index"
							>
								<td>{{ pg.pg_name }}</td>
								<td>{{ pg.pg_fee }}%</td>
								<td>
									<input
										v-model="pg.fee_rate"
										type="number"
										:rules="[rules.demical(pg, 'fee_rate', { min: 2, max: 2})]"
										class="box pa-5-10 width-fee "
									/> %
								</td>
								<td>
									<input
										v-model="pg.total_rate"
										class="box pa-5-10 width-fee "
										type="number"
										disabled
									/> %
								</td>
								<td
									v-if="item.agency_type == 'A001003'"
								>
									<v-icon
										class="pa-5 "
										:class="pg.fee_status == 1 ? 'bg-green color-white' : 'btn-default' "
										@click="setFeeStatus(pg, true)"
									>mdi mdi-power-plug</v-icon>
									<v-icon
										class="pa-5 "
										:class="pg.fee_status != 1 ? 'bg-red color-white' : 'btn-default' "
										@click="setFeeStatus(pg, false)"
									>mdi mdi-power-plug-off</v-icon>
								</td>
								<td
									v-else
								>
									<v-icon
										class="pa-5 "
										:class="pg.fee_status == 1 ? 'bg-green color-white' : 'btn-default' "
										@click="pg.fee_status = 1"
									>mdi mdi-power-plug</v-icon>
									<v-icon
										class="pa-5 "
										:class="pg.fee_status != 1 ? 'bg-red color-white' : 'btn-default' "
										@click="pg.fee_status = 0"
									>mdi mdi-power-plug-off</v-icon>
								</td>
							</tr>
							<tr>
								<td colspan="5" class="text-left bg-base">정기 결제</td>
							</tr>
							<tr
								v-for="(pg, index) in pg_bill_list"
								:key="'pg_' + index"
							>
								<td>{{ pg.pg_name }}</td>
								<td>{{ pg.pg_fee }}%</td>
								<td>
									<input
										v-model="pg.fee_rate"
										type="number"
										:rules="[rules.demical(pg, 'fee_rate', { min: 2, max: 2})]"
										class="box pa-5-10 width-fee "
									/> %
								</td>
								<td>
									<input
										v-model="pg.total_rate"
										class="box pa-5-10 width-fee "
										type="number"
										disabled
									/> %
								</td>
								<td
									v-if="item.agency_type == 'A001003'"
								>
									<v-icon
										class="pa-5 "
										:class="pg.fee_status == 1 ? 'bg-green color-white' : 'btn-default' "
										@click="setBillFeeStatus(pg, true)"
									>mdi mdi-power-plug</v-icon>
									<v-icon
										class="pa-5 "
										:class="pg.fee_status != 1 ? 'bg-red color-white' : 'btn-default' "
										@click="setBillFeeStatus(pg, false)"
									>mdi mdi-power-plug-off</v-icon>
								</td>
								<td
									v-else
								>
									<v-icon
										class="pa-5 "
										:class="pg.fee_status == 1 ? 'bg-green color-white' : 'btn-default' "
										@click="pg.fee_status = 1"
									>mdi mdi-power-plug</v-icon>
									<v-icon
										class="pa-5 "
										:class="pg.fee_status != 1 ? 'bg-red color-white' : 'btn-default' "
										@click="pg.fee_status = 0"
									>mdi mdi-power-plug-off</v-icon>
								</td>
							</tr>

							<tr
								v-if="item.agency_type == 'A001001'"
							>
								<td>카드 결제</td>
								<td>-</td>
								<td>
									<input
										v-model="item.sales_fee"
										type="number"
										:rules="[rules.demical(item, 'sales_fee', { min: 2, max: 2})]"
										class="box pa-5-10 width-fee "
									/> %
								</td>
								<td>
									<input
										class="box pa-5-10 width-fee "
										type="number"
										:value="text_upper_sales_fee | toFixed(2)"
										disabled
									/> %
								</td>
								<td>-</td>
							</tr>
							<tr>
								<td>무통장 입금</td>
								<td>-</td>
								<td>
									<input
										v-model="item.sales_fee_bank"
										type="number"
										:rules="[rules.demical(item, 'sales_fee_bank', { min: 2, max: 2})]"
										class="box pa-5-10 width-fee "
									/> %
								</td>
								<td>
									<input
										class="box pa-5-10 width-fee "
										type="number"
										:value="text_upper_sales_fee_bank | toFixed(2)"
										disabled
									/> %
								</td>
								<td>-</td>
							</tr>
							</tbody>
						</table>
					</div>

					<div class="mt-10">
						<h6>정산 정보</h6>
						<table class="table th-left td-left">

							<col width="120px">
							<col width="auto">
							<col width="120px">
							<col width="auto">
							<tbody>
							<tr>
								<th>정산주기</th>
								<td class="text-left" colspan="3">영업일 기준 / 월 정산</td>
							</tr>
							<tr>
								<th>은행</th>
								<td>
									<select
										v-model="item.bank_code"
										class="input-box"
									>
										<option :value="''">은행을 선택하세요</option>
										<option
											v-for="code in codes.B001.items"
											:key="code.total_code"
											:value="code.total_code"
										>{{ code.code_name}}</option>
									</select>
								</td>
								<th>계좌번호</th>
								<td>
									<input
										v-model="item.bank_account"
										type="number"
										class="input-box full-width"
										:rules="[rules.max(item, 'bank_account', 25)]"
										placeholder="정산 계좌 번호"
									/>
								</td>
							</tr>
							<tr>
								<th>예금주</th>
								<td>
									<input
										v-model="item.bank_holder"
										class="input-box full-width"
										placeholder="정산 계좌 예금주"
										maxlength="15"
									/>
								</td>
								<th>이메일</th>
								<td>
									<input
										v-model="item.bank_email"
										type="email"
										class="input-box full-width"
										placeholder="세금계산서 발행 이메일"
										maxlength="50"
									/>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div class="flex-1 bg-white pa-10">
					<div class="under-line-identify">
						<h6 class="mr-10" style="display: inline-block">상점 정보</h6>
						<button
							v-show="item.agency_type == 'A001003'"
							@click="isCopy "
							class="btn-inline btn-primary radius-20 pa-10-20 size-px-10"
						>대리점 코드 발급</button>
					</div>

					<table class="mt-10 table th-left td-left">
						<col width="120px">
						<col width="auto">
						<col width="120px">
						<col width="auto">
						<tbody>

						<tr>
							<th>일반 상품</th>
							<td>
								<button
									v-for="(code, index) in codes.S004.items"
									:key="code.total_code + '_' + index"

									class="pa-5-10 mr-1"
									:class="item.sales_status == code.code_value ? 'bg-' + code.code_color: 'bg-default'"
									@click="item.sales_status = code.code_value "
								>{{ code.code_name }}</button>
							</td>

							<th>매입몰 여부</th>
							<td>
								<label
									@click="item.sales_type == '1' ? item.sales_type = '' : item.sales_type = '1'"
								>
									<v-icon
										v-if="item.sales_type == '1'"
										class="color-primary"
									>mdi mdi-checkbox-marked</v-icon>
									<v-icon
										v-else
									>mdi mdi-checkbox-blank-outline</v-icon>
									매입몰
								</label>
							</td>
						</tr>
						<tr>
							<th>상점명 <span class="color-red">*</span></th>
							<td colspan="3">
								<input
									type="text"
									v-model="item.shop_name"
									maxlength="50"
									class="input-box-inline"
									placeholder="상점명을 입력하세요"
								/>
							</td>
						</tr>
						<tr
							v-if="item.agency_type == 'A001003'"
						>
							<th>상점 로고</th>
							<td colspan="3">
								<div>
									<label
										class="box pa-10 justify-space-between"
									>
										{{ logo_img_name }}
										<v-icon
											class="color-icon"
										>mdi mdi-image</v-icon>

										<input_file
											v-show="false"
											accept="image/*" @change="setFile2"
										/>
									</label>
								</div>

								<div
									v-if="item_upload_logo_img.src"
									class=" mt-10"
								>
									<div
										class="" style="position: relative"
									>
										<img
											:src="item_upload_logo_img.src"
											style="max-width: 180px"
										/>
										<button class="item_close" style="background-color: black">
											<v-icon
												@click="removeDelivery"
											>mdi-close</v-icon>
										</button>
									</div>
								</div>
								<div class="mt-10">
									<p>{{  item_upload_logo_img.name }}</p>
								</div>
							</td>
						</tr>
						<tr>
							<th>사업자 구분 <span class="color-red">*</span></th>
							<td colspan="3">
								<button
									v-for="(code, index) in codes.B002.items"
									:key="code.total_code + '_' + index"

									class="pa-5-10"
									:class="item.business_type == code.total_code ? 'bg-green' : 'bg-default'"

									@click="item.business_type = code.total_code"
								>{{ code.code_name }}</button>
							</td>
						</tr>
						<tr
							v-if="item.business_type == 'B002002'"
						>
							<th>사업자등록번호 <span class="color-red">*</span></th>
							<td colspan="3">
								<input
									type="number"
									v-model="item.front"
									:ruels="[rules.max(item, 'front', 10)]"
									class="input-box-inline"
									placeholder="사업자 등록 번호"
								/>
							</td>
						</tr>
						<tr
							v-if="item.business_type == 'B002001'"
						>
							<th>사업자등록번호 <span class="color-red">*</span></th>
							<td>
								<input
									type="number"
									v-model="item.front"
									:ruels="[rules.max(item, 'front', 10)]"
									class="input-box-inline"
									placeholder="사업자 등록 번호"
								/>
							</td>
							<th>법인등록번호 <span class="color-red">*</span></th>
							<td>
								<input
									type="number"
									v-model="item.back"
									:ruels="[rules.max(item, 'back', 13)]"
									class="input-box-inline"
									placeholder="법인 등록 번호"
								/>
							</td>
						</tr>
						<tr
							v-if="item.business_type == 'B002003'"
						>
							<th>주민등록 번호 <span class="color-red">*</span></th>
							<td colspan="3">
								<input
									v-model="item.front"
									type="number"
									:ruels="[rules.max(item, 'front', 6)]"
									class="input-box-inline mr-10"
									placeholder="주민등록 앞번호"
								/>
								<input
									v-model="item.back"
									type="password"
									maxlength="7"
									class="input-box-inline"
									placeholder="주민등록 뒷번호"
								/>
							</td>
						</tr>
						<tr>
							<th>사업장 주소 <span class="color-red">*</span></th>
							<td colspan="3">

								<input
									v-model="item.shop_zip_code"

									class="input-box-inline mr-10"
									type="text" placeholder="우편번호"
									readonly

									@click="daumPost('company')"
								>

								<button
									class="box pa-5-10 bg-identify"
									@click="daumPost('company')"
								>주소 검색</button>

								<input
									v-model="item.shop_address"

									class="input-box full-width mt-10" type="text" placeholder="주소"
									readonly

									@click="daumPost('company')"
								>
								<input
									v-model="item.shop_address_detail"

									class="input-box full-width mt-10" type="text" placeholder="상세주소를 입력하세요."
									maxlength="50"
								>
							</td>
						</tr>
						<tr
							v-if="item.agency_type == 'A001003'"
						>
							<th>1회 결제 한도</th>
							<td colspan="3">
								<div  class="flex-row items-center">
									<div class="mr-10 flex-row items-center">
										<input
											v-model="item.payment_limit"
											class="input-box full-width mr-10"
											placeholder="1회 결제 한도 금액을 설정하세요"
											type="number"
											:rules="[$rules.id(item, 'payment_limit', { min: 4, max: 8})]"
										/> 원
									</div>
									<label>
										<v-icon
											v-if="item.is_payment_limit == 1"
											@click="item.is_payment_limit = 0"
											class="color-success"
										>mdi-checkbox-marked</v-icon>
										<v-icon
											v-else
											@click="item.is_payment_limit = 1"
										>mdi-checkbox-blank-outline</v-icon>
										한도 적용
									</label>
								</div>
							</td>
						</tr>
						<tr
							v-if="item.agency_type == 'A001003'"
						>
							<th>쇼핑몰 도메인</th>
							<td>

								<input
									v-model="item.shop_link"
									class="input-box full-width"
									placeholder="도메인을 입력하세요"
								/>
							</td>

							<th>SMS</th>
							<td>
								<select
									v-model="item.sms_uid"
									class="input-box"
								>
									<option>선택하세요</option>
									<option
										v-for="(sms, sms_index) in items_sms"
										:key="'sms_' + sms_index"
										:value="sms.uid"
									>{{ sms.sms_name}}</option>
								</select>
							</td>
						</tr>

						<tr
							v-if="false"
						>
							<th>보유 PG 정보</th>
							<td colspan="3">
								<select
									v-model="item.pg_code"
									class="pa-5-10 mr-10"
									:disabled="item_pg.pg_status == '1'"
								>
									<option value="">PG사</option>
									<option
										v-for="(code, index) in codes.P004.items"
										:key="code.total_code + '_' + index"
										:value="code.code_value"
									>{{ code.code_name }}</option>
								</select>

								<input
									v-model="item.pgMerchNo"
									class="pa-5-10 mr-10 box"
									placeholder="가맹점 ID"
									maxlength="20"
									:disabled="item_pg.pg_status == '1'"
								/>

								<input
									v-model="item.pgMerchName"
									class="pa-5-10 mr-10 box"
									placeholder="터미널 ID"
									maxlength="15"
									:disabled="item_pg.pg_status == '1'"
								/>

								<input
									v-model="item.pg_fee"
									class="pa-5-10 mr-10 box"
									placeholder="PG 결제 수수료"
									:rules="[$rules.demical(item, 'pg_fee', {min: 2, max:2})]"
									:disabled="item_pg.pg_status == '1'"
								/>

								<div
									v-if="item_pg.uid && item_pg.pg_status != '1'"
									class="color-red mt-10"
								>보유 PG 정보 확인 중입니다</div>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>

		<div
			class="mt-30 justify-center"
		>
			<button
				class="bg-identify pa-10-20 mr-10"
				@click="save"
			>저장</button>
			<button
				class="box pa-10-20"
				@click="toList"
			>목록</button>
		</div>

		<DaumPost
			:overlay="is_post"
			:config="daum_config"
			@callBack="addPost"
		></DaumPost>

		<Modal
			:is_modal="is_modal"
			:option="{}"
			:top="true"
			:bottom="true"

			title="비밀번호 초기화"
			content="비밀번호를 초기화 하시겠습니까?"
			width="360px"

			content_class="ptb-50"

			@click="doPassword"
			@close="clear"
			@cancel="clear"
		></Modal>

		<Modal
			:is_modal="is_bussiness"
			:option="modal_option"
			:width="'560px'"
			:height="'480px'"

			@cancel="is_bussiness = false"
			@close="is_bussiness = false"
			@click="setUpper"
		>
			<div
				slot="modal-content"
			>
				<div>
					<input
						v-model="item_search.agency_name"
						class="input-box-inline mr-10"
						placeholder="영업점 명을 입력하세요"
					/>
					<button
						@click="getUpper"
						class="box pa-5-10 bg-base"
					>조회</button>
				</div>
				<table
					class="table mt-10"
				>
					<thead>
					<tr>
						<th>구분</th>
						<th>영업점 명</th>
						<th>선택</th>
					</tr>
					</thead>
					<tbody>
					<tr
						v-for="(upper, index) in items_upper"
						:key="'upper_' + index"
						:class="{on: upper.uid == item_upper_select.uid}"
					>
						<td>{{ upper.agency_type_name}}</td>
						<td class="text-left">{{ upper.agency_name}}</td>
						<td><button @click="selectUpper(upper)" class="btn-blue pa-5-10">선택</button></td>
					</tr>
					</tbody>
				</table>
			</div>
		</Modal>

		<Modal
			:is_modal="is_on_qr"
			:option="modal_option2"

			:top="true"
			:bottom="true"
			:slot_bottom="true"
			title="대리점 QR 코드"

			width="360px"
			content_class="bg-white text-center"

			@close="close"
			@click="close"
			@cancel="close"
		>

			<div slot="modal-content" class="bg-white text-center">
				<qr-code :text="codes.live_url + encodeURI(Base64.encode(item.account_id))"></qr-code>
			</div>
			<template
				slot="modal-bottom"
			>
				<button
					class="flex-1 bg-blue pa-10"
					@click="copy"
				>URL 복사</button>
				<button
					class="flex-1 bg-default pa-10"
					@click="close"
				>닫기</button>
			</template>
		</Modal>
	</div>
</template>

<script>

import DaumPost from "@/components/Daum/DaumPost";
import Modal from "@/components/Modal";
import input_file from '@/components/InputFile'
import VueQRCodeComponent from 'vue-qrcode-component'
import Vue from 'vue'
Vue.component('qr-code', VueQRCodeComponent)

export default {
	name: 'AgencyDetail'
	,
	components: {DaumPost, Modal, input_file},
	props: ['Axios', 'user', 'codes', 'rules', 'date', 'TOKEN', 'Base64']
	,data: function(){
		return {
			program: {
				name: '영업점 상세정보'
				, top: true
				, title: true
				, bottom: false
			}
			,item: {
				agency_type: ''
				, bank_code: ''
				, agency_upper: ''
				, business_type: ''
				, pg_code: ''
				, sales_pg_uid: ''
				, sales_type: ''
				, sms_uid: ''
			}
			, item_pg: {
				uid: ''
			}
			, sales_pg_uid: ''
			,is_data_pick: false
			,is_modal: false
			,is_post: false
			,daum_config: {
				width: '360px'
			}
			,items_upper: []
			, item_logo_img: ''
			, item_upload_logo_img: {}
			, items_pg_list: []
			, is_bussiness: false
			, items_distributor: []
			, modal_option: {
				top: true
				,title: '소속 영업점 조회'
				,bottom: true
			}
			, item_upper_company: {
				agency_upper: ''
				, agency_name: ''
			}
			, item_upper_distributor: {
				agency_upper: ''
				, agency_name: ''
			}
			, item_search: {
				agency_name: ''
			}
			, item_upper: {
				uid: ''
				, agency_name: ''
			}
			, item_upper_select: {

			}
			, items_sms: []
			, is_on_qr: false
			, modal_option2: {

			}
		}
	}
	,computed: {

		logo_img_name: function(){
			let name = '로고 이미지'
			return name
		}
		, text_fee_rate: function(){
			let t = '공급가 마진'
			if(this.item.agency_type == 'A001003'){
				t = '공급가 마진'
			}
			return t
		}
		, text_bg_pg: function(){
			let t = ''

			if(this.item.sales_pg_uid && this.item.sales_pg_uid == this.item_pg.uid){
				if(this.item_pg.pg_status == '1'){
					t = 'bg-success'
				}else{
					t = 'bg-error'
				}
			}else if(!this.item_pg.uid && this.item.sales_pg_uid === this.item_pg.uid){
				t = 'bg-success'
			}

			return t
		}
		, text_upper: function(){
			let t = ''

			if(this.item_upper.uid){
				t = this.item_upper.agency_name
			}

			return t
		}
		, is_search_upper: function(){
			let t = false
			if(this.item.agency_type != 'A001001'){
				t = true
			}
			return t
		}
		, pg_sales_list: function(){
			return this.items_pg_list.filter( (item) => {
				if(item.pg_type == 'P005001'){
					item.total_rate =  Number(item.pg_fee) + Number(item.fee_rate) + Number(item.fee_upper ? item.fee_upper : 0) + Number(item.distributor_fee_rate ? item.distributor_fee_rate : 0)
					return item
				}
			})
		}
		, pg_bill_list: function(){
			return this.items_pg_list.filter( (item) => {
				if(item.pg_type == 'P005002'){
					item.total_rate =  Number(item.pg_fee) + Number(item.fee_rate) + Number(item.fee_upper ? item.fee_upper : 0) + Number(item.distributor_fee_rate ? item.distributor_fee_rate : 0)
					return item
				}
			})
		}
		, text_upper_sales_fee: function(){
			let t = 0
			t += Number(this.item.sales_fee)
			return t
		}
		, text_upper_sales_fee_bank: function(){
			let t = 0
			t += Number(this.item.sales_fee_bank)
			return t
		}
	}
	, methods: {
		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getAgency'
					,data: {
						agency_uid: this.$route.params.idx
					}
				})
				if(result.success){
					this.item = result.data.info
					if(result.data.pg_info){
						this.item_pg = result.data.pg_info
					}
					if(result.data.sms_list){
						this.items_sms = result.data.sms_list
					}
					this.item_upload_logo_img = {
						src: this.item.shop_logo_view
						, name: this.item.shop_logo_view
						, type: 'image'
					}
					this.item_logo_img = ''
					this.item_upper = {
						uid: this.item.agency_upper
						, agency_id: this.item.agency_upper_id
						, agency_name: this.item.agency_upper_name
					}
					await this.getAgencyUpper()
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, save: async function(){
			try{
				this.$bus.$emit('on', true)

				if(this.item_logo_img){
					this.$set(this.item, 'item_logo_img', this.item_logo_img)
				}

				this.$set(this.item, 'sales_pg_list', JSON.stringify(this.items_pg_list))

				const result = await this.$request.init({
					method: 'post'
					,url: 'management/putAgency'
					,data: this.item
				})
				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: result.message})
					await this.getData()
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		,daumPost: function () {
			this.is_post = true
		}
		, addPost: function (call) {

			this.$set(this.item, 'shop_zip_code', call.zonecode)
			this.$set(this.item, 'shop_address', call.address)

			this.is_post = false
		}
		, toList: function(){
			this.$router.back()
		}
		, getUpper: async function(){
			try{
				this.item_upper_select = {}
				const result = await this.$request.init({
					method: 'get'
					,url: 'admin/getUpper'
					,data: {
						agency_type: this.item.agency_type
						, agency_name: this.item_search.agency_name
						, agency_uid: this.item.agency_uid
					}
				})
				if(result.success){
					this.items_upper = result.data.result
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,clear: function(){
			this.is_modal = false
		}
		,isPassword: function(){
			this.clear()
			this.is_modal = true
		}
		,doPassword: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/postAgencyPasswordReset'
					,data: {
						uid: this.$route.params.idx
					}
				})
				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: result.message})
					this.clear()
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, setFile2: function(e){
			console.log('setFile2', e)

			let self = this
			for(let file of e){
				console.log(`file` , file)
				this.item_logo_img = file

				const reader = new FileReader()
				let data = {
					name: file.name
					, size: file.size
					, type: file.type
				}

				reader.onload = function(e){
					console.log('reader.onload')
					data.src = e.target.result
					self.item_upload_logo_img = data
				}

				reader.readAsDataURL(file)
			}
		}
		, removeDelivery: async function(){
			if(this.item_logo_img){

				if(this.item.shop_logo){
					this.item_upload_logo_img = {
						src: this.item.shop_logo
						, name: this.item.shop_logo
						, type: 'image'
					}
					this.item_logo_img = {}
				}else{
					this.item_upload_logo_img = {}
					this.item_logo_img = {}
				}
			}else{
				if(confirm("삭제하시겠습니까?")){

					try{
						const result = await this.$request.init({
							method: 'post'
							,url: 'management/postShopLogoDelete'
							,data: {
								uid: this.item.uid
							}
						})

						if(result.success){
							this.item_upload_logo_img = {}
							this.item_logo_img = {}
						}else{
							this.$bus.$emit('notify', { type: 'error', message: result.message })
						}
					}catch (e) {
						console.log(e)
					}
				}
			}
		}
		, getPgList: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getAvailablePgList'
					,data: {
						agency_type: this.item.agency_type
						, upper_id: this.item_upper.agency_id
						, agency_id: this.item.agency_id
					}
				})
				if(result.success){
					this.items_pg_list = result.data
				}else{
					this.items_pg_list = []
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, setUpper: function(){
			this.item_upper = this.item_upper_select
			this.is_bussiness = false
			this.getPgList()
		}
		, selectUpper: function(upper){
			this.item_upper_select = upper
			this.item.agency_upper = upper.uid
		}
		, onAgencyType: function(){

			this.is_bussiness = true

			this.items_upper = []
		}
		, getAgencyUpper: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getAgencyUpper'
					,data: {
						agency_type: this.item.agency_type
					}
				})
				if(result.success){
					this.items_upper = result.data
					await this.getPgList()
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, setFeeStatus: function(item, type){
			this.items_pg_list.filter( (pg) => {
				if(pg.pg_type == 'P005001'){
					pg.fee_status = 0
				}
			})
			if(type){
				item.fee_status = 1
			}
		}
		, setBillFeeStatus: function(item, type){
			this.items_pg_list.filter( (pg) => {
				if(pg.pg_type == 'P005002') {
					pg.fee_status = 0
				}
			})
			if(type){
				item.fee_status = 1
			}
		}
		,isCopy: function(){
			this.is_on_qr = true
		}
		,close: function(){
			this.is_modal = false
			this.is_on_qr = false
			this.is_member = false
		}

		,clipBoard: function (val){
			const t = document.createElement("textarea");
			document.body.appendChild(t);
			t.value = val;
			t.select();
			document.execCommand('copy');
			document.body.removeChild(t);
		}

		,copy: function (){

			this.clipBoard((this.item.shop_link ? this.item.shop_link + '/Auth/Join/' : this.codes.live_url) + encodeURI(this.Base64.encode(this.item.agency_id)));
			alert('대리점 회원가입 바로가기 링크가 복사되었습니다.');
		}
	}
	, created() {
		this.$emit('onLoad', this.program)
		this.getData()
	}
}
</script>

<style>
.width-fee { width: 60px !important; text-align: right;}
.v-btn__content { color: #333 !important;}

.v-file-input__text--placeholder {
	color: #bbb !important;
	font-size: 14px;
}

.theme--light.v-icon {
	color: #bbb;
}

.item_close {
	position: absolute; right: 10px; top: 10px
}
</style>
